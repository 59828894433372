import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = ({ location }) => (
  <Layout>
	  <SEO
		  location={location}
		  title="Page Not Found"
	  />
	  <div className="page-content notfound-page">
		  <h2>Page not found :(</h2>
	  </div>
  </Layout>
);

export default NotFoundPage;
